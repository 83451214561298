import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from 'src/app/models/currency';
import { Unit } from 'src/app/models/unit';
import { BuyService } from 'src/app/services/buy.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'app-store-mode-offer-price-dialog',
  templateUrl: './store-mode-offer-price-dialog.component.html',
  styleUrls: ['./store-mode-offer-price-dialog.component.scss']
})
export class StoreModeOfferPriceDialogComponent implements OnInit {
  units: Unit[] = [];
  form: FormGroup;
  currencies: Currency[] = [];
  isLoading: boolean = false;
  currency: string;
  unit: string;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private _unitService: UnitService,
    private _formBuilder: FormBuilder,
    private _buyListingService: BuyService,
    private snackBarService: SnackbarService,
    private _currencyService: CurrencyService,
    public dialogRef: MatDialogRef<StoreModeOfferPriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getUnits();
    this.getCurrencies();
    this.patchValue();
  }

  initForm(): void {
    this.form = this._formBuilder.group({
      unit: [''],
      qty: ['', Validators.required],
      price: ['', Validators.required],
      currency: [''],
      message: ['', Validators.required]
    });
  }

  getUnits(): void {
    this._unitService.getUnits().subscribe((res) => {
      this.units = res.data;
    });
  }

  getCurrencies(): void {
    this._currencyService.getCurrencies().subscribe(res => {
      this.currencies = res.data;
    });
  }

  patchValue() {
    this.form.patchValue({
      unit: this.data?.buy?.unit?._id,
      price: this.data?.buy?.prices[0],
      currency: this.data?.buy?.currency?._id,
      qty: this.data?.buy?.qty
    });

    const data = this.form.value;

    this.currency = this.data?.buy?.currency?.symbol;
    this.unit = this.translate.currentLang === 'en' ? (this.data?.buy?.unit?.name_en || this.data?.buy?.unit?.symbol) : (this.data?.buy?.unit?.name || this.data?.buy?.unit?.symbol);
    this.translate
      .get("MessagePage.hello_shop_I_want_to_request_offer",
        {
          qty: new DecimalPipe('en').transform(data?.qty, '.0') + " " + this.unit,
          price: new CurrencyPipe('en').transform(data?.price, this.currency, 'symbol', '.0')
        }
      )
      .subscribe(async (res: string) => {
        this.form.get('message').patchValue(res);
      });
  }

  onUnitChange($event): void {
    this.form.patchValue({ unit: $event.value })
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }
    try {
      const body = {
        qty: + this.form.value.qty,
        unit: this.form.value.unit,
        price: + this.form.value.price,
        currency: this.form.value.currency,
        buy_listing: this.data.buy._id,
        message: this.form.value.message
      }
      let unitSymbol = this.units.find(unit => unit._id === body.unit)?.symbol;
      let currencySymbol = this.currencies.find(currency => currency._id === body.currency)?.symbol;
      const res: any = await this._buyListingService.offerPrice(body, unitSymbol, currencySymbol);

      const params = { tab: "shop", user: res.data.buy_listing.user._id };
      await this.router.navigate(["/store-message"], {
        relativeTo: this._route,
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.dialogRef.close(res);
      this.isLoading = false;
    } catch (err) {
      console.error(err);
      this.snackBarService.onShowSnackbar('Status.makeOfferFailed');
      this.router.navigate(['/market-demand']);
    }
  }
}
