import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { ChatService } from 'src/app/services/chat.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SupportDialogComponent } from 'src/app/shares/support-dialog/support-dialog.component';
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  unreadMessageCountSubscription: Subscription;
  realtimeUnreadMsgCountSubscription: Subscription;
  unreadMsgCount: number = 0;
  userId: string;
  constructor(
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private chatService: ChatService,
  ) {}

  ngOnInit(): void {
    if (this.unreadMessageCountSubscription) {
      this.unreadMessageCountSubscription.unsubscribe();
    }
    // if (this.realtimeUnreadMsgCountSubscription) {
    //   this.realtimeUnreadMsgCountSubscription.unsubscribe();
    // }
    this.getUnreadMessageCount();
    this.unreadMessageCountSubscription = this.chatService.unreadMsgCountChange
      .pipe()
      .subscribe(this.onUnreadMessageCountChange.bind(this));
    this.userId = this.localStorageService.get(LocalStorageEnum.user_id);
    this.realtimeUnreadMsgCountSubscription = this.chatService
      .subscribeToRealtimeUnreadMsgCount(this.userId)
      .pipe()
      .subscribe(this.onRealtimeUnreadMsgCountChange.bind(this));
  }

  ngOnDestroy(): void {
    if (this.unreadMessageCountSubscription) {
      this.unreadMessageCountSubscription.unsubscribe();
    }

    if (this.realtimeUnreadMsgCountSubscription) {
      this.realtimeUnreadMsgCountSubscription.unsubscribe();
    }
  }

  getUnreadMessageCount() {
    this.chatService.getUserUnreadMessageCount();
  }

  onUnreadMessageCountChange(value: any) {
    this.unreadMsgCount = Number(value.data.unread_messages_count);

    // unsubscribe since it is only trigger when called.
    this.unreadMessageCountSubscription.unsubscribe();
  }

  onRealtimeUnreadMsgCountChange(value: any) {
    this.unreadMsgCount = Number(value.data.unread_messages_count);
  }

  openSupportDialog() {
    this.dialog.open(SupportDialogComponent, {})
  }
}
