<div class="support-right">
    <div class="img-logo">
        <img class="w-82 kas-logo" src="../../../assets/imgs/logo.svg" alt="logo-image" />
    </div>
    <h3 class="mat-h3 d-flex support-title my-12">{{ "Support.title" | translate }}</h3>

    <ng-container *ngFor="let support of support_card">
        <div *ngIf="support.is_auth" class="support-card" (click)="openInNewTab(support.redirect_to)">
            <div class="support-card-left">
                <div class="centent-icon base-shadow rounded-6">
                    <mat-icon [svgIcon]="support.svg_icon" [class]="support.class_icon"></mat-icon>
                </div>
                <div class="label-card">{{ support.label | translate }}</div>
            </div>
            <mat-icon svgIcon="icon-arrow-next" class="mr-6 chat-icon"></mat-icon>
        </div>
    </ng-container>
</div>
