import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProfileLayoutComponent } from './components/profile-layout/profile-layout.component';
import { StoreModeLayoutComponent } from './components/store-mode-layout/store-mode-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { SearchLayoutComponent } from './components/search-layout/search-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'kas-data-entry-mobile',
    loadChildren: () =>
      import(
        './routes/kas-data-entry-mobile/kas-data-entry-mobile.module'
      ).then((m) => m.KasDataEntryMobileModule),
  },
  {
    path: 'kas-data-entry-soil-map',
    loadChildren: () =>
      import(
        './routes/soil-locate/soil-locate.module'
      ).then((m) => m.SoilLocateModule),
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'sell-product',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/sell-product/sell-product.module').then(
            (m) => m.SellProductModule
          ),
      },
      {
        path: 'shop',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/shop/shop.module').then((m) => m.ShopModule),
      },
      {
        path: 'message',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/message/message.module').then(
            (m) => m.MessageModule
          ),
      },
      {
        path: 'announcement',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/announcement/announcement.module').then(
            (m) => m.AnnouncementModule
          ),
      },
      {
        path: 'my-order',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/my-order/my-order.module').then(
            (m) => m.MyOrderModule
          ),
      },
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'post',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/post/post.module').then((m) => m.PostModule),
      },
      {
        path: 'product',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/post-product/post-product.module').then(
            (m) => m.PostProductModule
          ),
      },
      {
        path: 'kas-data-entry',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/kas-data-entry/kas-data-entry.module').then((m) => m.KasDataEntryModule)
      },
      {
        path: 'weather-forcast',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/weather-forcast/weather-forcast.module').then((m) => m.WeatherForcastModule)
      },
      {
        path: 'report-content',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/report-content/report-content.module').then(m => m.ReportContentModule)
      }
    ],
  },
  {
    path: '',
    component: SearchLayoutComponent,
    children: [
      {
        path: 'search-all',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/search/search-all/search-all.module').then(
            m => m.SearchAllModule
          )
      },
      {
        path: 'search-user',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/search/search-user/search-user.module').then(
            m => m.SearchUserModule
          )
      },
      {
        path: 'search-product',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/search/search-product/search-product.module').then(
            m => m.SearchProductModule
          )
      },
      {
        path: 'search-buy-listing',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/search/search-buy-listing/search-buy-listing.module').then(
            m => m.SearchBuyListingModule
          )
      },
      {
        path: 'search-shop',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/search/search-shop/search-shop.module').then(
            m => m.SearchShopModule
          )
      },
      {
        path: 'search-post',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/search/search-post/search-post.module').then(
            m => m.SearchPostModule
          )
      },
    ]
  },
  {
    path: '',
    component: StoreModeLayoutComponent,
    children: [
      {
        path: 'store-message',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/message/message.module').then(
            (m) => m.MessageModule
          ),
      },
      {
        path: 'store-info',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/store-info/store-info.module').then(
            (m) => m.StoreInfoModule
          ),
      },
      {
        path: 'my-store-product',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/my-product/my-product.module').then(
            (m) => m.MyProductModule
          ),
      },
      {
        path: 'market-demand',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './routes/my-product-buy-list/my-product-buy-list.module'
          ).then((m) => m.MyProductBuyListModule),
      },
      {
        path: 'payment-account',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './routes/payment/payment.module'
          ).then(m => m.PaymentModule)
      },
      {
        path: 'order-management',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './routes/order-management/order-management.module'
          ).then(m => m.OrderManagementModule)
      }
    ],
  },

  {
    path: 'profile',
    component: ProfileLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
    ],
  },

  {
    path: 'users/:id',
    component: MainComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/user/user.module').then((m) => m.UserModule),
      },
    ],
  },

  {
    path: 'policy',
    loadChildren: () =>
      import('./routes/policy-page/policy-page.module').then((m) => m.PolicyPageModule),
  },

  {
    path: 'help',
    loadChildren: () =>
      import('./routes/support-page/support-page.module').then((m) => m.SupportPageModule),
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./routes/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./routes/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'forget-password',
    loadChildren: () =>
      import('./routes/forget-password/forget-password.module').then(
        (m) => m.ForgetPasswordModule
      ),
  },
  {
    path: 'apple',
    loadChildren: () =>
      import('./routes/apple/apple.module').then((m) => m.AppleModule),
  },
  {
    path: 'camdigikey',
    loadChildren: () => import('./routes/camdigikey/camdigikey.module').then((m) => m.CamdigikeyModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
