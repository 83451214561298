<div class="dialog-header mb-0" mat-dialog-title>
    <h3 class="mat-h3 mb-8 text">
        {{ "Order" | translate }}
      </h3>
</div>
<mat-divider class="divider-2"></mat-divider>
<div mat-dialog-content>
    <div class="d-flex align-items-center justify-content-center g-12 p-12">
        <img src = "../../../../../assets/imgs/empty-bank.svg" class="bank-icon">
    </div>
    <div class="d-grid align-items-center justify-content-center gap-4 p-4">
        <h3 class="mat-h3 mb-8 text text-center">
            {{ "ShopPaymentMethodTitle" | translate }}
        </h3>
        <h5 class="mb-8 text">
            {{ "ShopPaymentMethodDescription" | translate }}
        </h5>
    </div>
</div>

<div class="mt-12 d-flex justify-content-center" mat-dialog-action align="right">
    <button mat-flat-button class="btn-md-1" type="submit" color="primary" routerLink="/payment-account" (click) = "createClick()">
    <mat-icon svgIcon="icon-make-payment"></mat-icon>
    {{ "CreatePaymentMethod" | translate }}
  </button>
</div>
