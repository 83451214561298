import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-created-payment-alert-modal-popup',
  templateUrl: './created-payment-alert-modal-popup.component.html',
  styleUrls: ['./created-payment-alert-modal-popup.component.scss']
})
export class CreatedPaymentAlertModalPopupComponent implements OnInit {

  constructor(
     private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

  }

  createClick(){
    this.dialog.closeAll();
  }

}
