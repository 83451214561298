// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://kas-core-stg.myoptistech.com',
  host_url: 'https://kas-portal-web-stg.myoptistech.com',
  auth_url: '/auth',
  portal_url: '/portal',
  weather_url: '/weather',
  resource_url: '/resources',
  kumrong_url: '/kumrong',
  chat_url: "https://kas-chat-api-stg.myoptistech.com",
  file_portal_url: 'https://kas-core-stg.myoptistech.com/portal/photos/',
  env_name: 'development',
  google_client_id: '148224380298-a3qv8qguknhgv6jh35n9cks9bd0rq8vu.apps.googleusercontent.com',
  facebook_client_id: '589570248869084',
  apple_login_service: "com.optistech.kas.portal.service",
  google_map_api_key: "AIzaSyAUDqPtpTcfKBAjTfcAAgYKV0TAHo1NARw",
  sentry_dsn: "https://2cf71527d98c46768b97dbbe9b9b9186@sentry.optistech.com/16"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
