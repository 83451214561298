import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit {
  auth: boolean;
  support_card: any[] = [];
  constructor(
    private authService: AuthService,
  ) {
    this.authService.authChange$.subscribe((isAuth) => {
      this.auth = isAuth;
    });

    this.support_card = [
      {
        svg_icon: 'icon-chat',
        label: 'Support.kas_portal_chat',
        redirect_to:
          'https://portal.kas.gov.kh/message?tab=user&user=66b2cc413f8f002a02f66b54',
        class_icon: 'chat-icon',
        is_auth: this.auth,
      },
      {
        svg_icon: 'icon-facebook',
        label: 'Support.facebook',
        redirect_to: 'https://www.facebook.com/kasdigitalplatform',
        class_icon: '',
        is_auth: true,
      },
      {
        svg_icon: 'icon-telegram',
        label: 'Support.telegram',
        redirect_to: 'https://t.me/+dY1yWvashl9iYjdl',
        class_icon: '',
        is_auth: true,
      },
      {
        svg_icon: 'icon-phone',
        label: 'Support.tel',
        redirect_to: '',
        class_icon: 'chat-icon',
        is_auth: true,
      },
    ];
  }

  ngOnInit(): void {
  }

  openInNewTab(url: string): void {
    if (!url) return;

    window.open(url, '_blank');
  }
}
